import React, { Component } from 'react'
import SiteLink from '@components/SiteLink'
import LeavingSiteModal from '@components/leaving-site-modal/leaving-site-modal.component'
import './site-map.component.scss'

class SiteMapComponent extends Component {
    constructor() {
        super()

        this.state = {
            showLeavingSiteModal: false,
            href: '',
        }

        this.handleShowModalClicked = this.handleShowModalClicked.bind(this)
        this.handleHideModal = this.handleHideModal.bind(this)
    }

    handleHideModal() {
        this.setState({
            showLeavingSiteModal: false,
        })
    }

    handleShowModalClicked() {
        this.setState({
            showLeavingSiteModal: true,
        })
    }

    openLink(link) {
        this.setState(() => {
            return { href: link }
        }, this.handleShowModalClicked())
    }

    render() {
        return (
            <div className='site-map-wrapper'>
                <div className="primary-container">
                <h1 className="primary-container-header top-padding">Site Map</h1>
                <div className="link-updates-container">
                    <div className="link-container">
                    <div className="content-wrapper">
                        <h2 className="section-header">About Narcolepsy</h2>
                        <SiteLink to="/about-narcolepsy/pathophysiology/" className="link">
                        Pathophysiology
                        </SiteLink>

                        <h2 className="section-header">Screening &amp; Diagnostic</h2>
                        <SiteLink to="/screening-and-diagnosis/recognizing-symptoms/" className="link">
                        Identifying Narcolepsy
                        </SiteLink>
                        <SiteLink to="/screening-and-diagnosis/screeners/" className="link">
                        Screeners
                        </SiteLink>
                        <SiteLink to="/screening-and-diagnosis/diagnostic-criteria/" className="link">
                        Diagnostic Criteria
                        </SiteLink>
                        <SiteLink to="/screening-and-diagnosis/diagnostic-process/" className="link">
                        Diagnostic Process
                        </SiteLink>
                        <SiteLink to="/screening-and-diagnosis/narcolepsy-differential-diagnosis/" className="link">
                        Diagnostic Challenges
                        </SiteLink>

                        <h2 className="section-header">Comorbidity Risk</h2>
                        <SiteLink to="/comorbidities-risk/cardiovascular-comorbidities/" className="link">
                        Cardiovascular Comorbidities
                        </SiteLink>
                        <SiteLink to="/comorbidities-risk/risk/" className="link">
                        Modifiable Risks for CV Disease
                        </SiteLink>
                        <SiteLink to="/comorbidities-risk/risk/#HighSodiumIntake" className="link margin-left">
                        High Sodium Intake and&nbsp;CV Disease
                        </SiteLink>
                        <SiteLink to="/comorbidities-risk/high-sodium-intake/" className="link margin-left">
                        Excess Sodium Intake Impact on Certain Body&nbsp;Systems
                        </SiteLink>
                        <SiteLink to="/comorbidities-risk/psychiatric-and-sleep-comorbidities/" className="link">
                        Psychiatric and Sleep Comorbidities
                        </SiteLink>

                    </div>

                    <div className="content-wrapper">
                        <h2 className="section-header">Disease Management</h2>
                        <SiteLink to="/disease-management/treatment-decisions/" className="link">
                        Management Considerations
                        </SiteLink>
                        <h2 className="section-header">Pediatric Patients</h2>
                        <SiteLink to="/pediatric-patients/burden-of-illness/" className="link">
                        Burden of Illness
                        </SiteLink>
                        <SiteLink to="/pediatric-patients/symptoms/" className="link">
                        Symptoms
                        </SiteLink>
                        <SiteLink to="/pediatric-patients/clinical-interview/" className="link">
                        Clinical Interview 
                        </SiteLink>
                        <SiteLink to="/pediatric-patients/differential-diagnosis/" className="link">
                        Differential Diagnosis
                        </SiteLink>
                        <SiteLink to="/pediatric-patients/screening/" className="link">
                        Screening
                        </SiteLink>
                        <SiteLink to="/pediatric-patients/patient-and-caregiver-perspectives/" className="link">
                        Patient and Caregiver Perspectives 
                        </SiteLink>
                        <h2 className="section-header">Stay Connected</h2>
                        <SiteLink to="/stay-connected/connect-with-us/" className="link">
                        Connect With Us
                        </SiteLink>
                        <SiteLink to="/stay-connected/narcolepsy-information-links/" className="link">
                        Sleep Organizations
                        </SiteLink>
                        <SiteLink to="/stay-connected/about-narcolepsy-link/" className="link">
                        About NarcolepsyLink
                        </SiteLink>
                    </div>
                    <div className="content-wrapper">
                        <h2 className="section-header">Resources</h2>

                        <SiteLink to="/resources/narcolepsy-expert-clinical-insights/" className="link">
                        Expert Clinical Insights
                        </SiteLink>
                        <SiteLink to="/resources/resource-center/" className="link">
                        Clinical Resource Kits
                        </SiteLink>
                        <SiteLink to="/resources/narcolepsy-faqs/" className="link">
                        Narcolepsy FAQs
                        </SiteLink>
                        <SiteLink to="/resources/glossary-of-terms/" className="link">
                        Glossary of Terms
                        </SiteLink>
                        <div className="privacy-use-wrapper">
                        <SiteLink to="/" className="link">
                            Home
                        </SiteLink>
                        <span
                            className="privacy-statement-link link"
                            onClick={() => this.openLink('https://www.jazzpharma.com/privacy-statement/')}
                        >
                            Privacy Statement
                        </span>
                        <span
                            className="privacy-statement-link link"
                            onClick={() => this.openLink('https://www.jazzpharma.com/terms-of-use/')}
                        >
                            Terms of Use
                        </span>
                        </div>
                    </div>
                    </div>
                </div>
                <hr />
                </div>
                <LeavingSiteModal
                isShowModal={this.state.showLeavingSiteModal}
                onClose={this.handleHideModal}
                href={this.state.href}
                location={this.props.location}
                />
            </div>
        )
    }
}

export default SiteMapComponent
